/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Period } from 'constants/enums';
import { Tariffs } from 'types';
import dateCreator from 'utils/dateCreator';

const initialState: Tariffs = {
  tariffs: {},
  providers: {},
  providerList: [],
  startDate: dateCreator.now.startOf('day'),
  endDate: dateCreator.now,
  period: Period.TODAY
};

const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    setAllTariffs(state, { payload }: PayloadAction<Tariffs>) {
      state.tariffs = payload.tariffs;
      state.providers = payload.providers;
      state.providerList = payload.providerList;
    },
    updateTariffDateRange(state, { payload: { period } }: PayloadAction<{ period: string }>) {
      state.period = period;

      switch (period) {
        case Period.TODAY:
          state.startDate = dateCreator.now.startOf('day');
          state.endDate = dateCreator.now;
          break;
        case Period.YESTERDAY:
          state.startDate = dateCreator.yesterday.startOf('day');
          state.endDate = dateCreator.yesterday;
          break;
        case Period.TOMORROW:
          state.startDate = dateCreator.tomorrow.startOf('day');
          state.endDate = dateCreator.tomorrow;
          break;
      }
    }
  }
});

export const { setAllTariffs, updateTariffDateRange } = tariffsSlice?.actions;
export const tariffs = tariffsSlice.reducer;
