/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import Checkbox from 'components/elements/checkbox';
import { useAppSelector } from 'customHooks';
import { useFormikContext } from 'formik';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { ADDRESS_FIELDS } from './constants';
import { LabelsData } from './helper';
import Lookup from './lookup';
import Manual from './manual';

interface IAddressLookup {
  label?: string;
  onCountryChange?: () => void;
  setHasCountryError?: Dispatch<SetStateAction<boolean>>;
}

const AddressLookup: FC<IAddressLookup> = ({ label, onCountryChange, setHasCountryError }) => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const { values, setValues, setFieldValue, setFieldTouched }: { [x: string]: any } =
    useFormikContext();
  const { language } = useAppSelector((state) => state.userDetails.personalInfo.preferences);

  const [data, setData] = useState<LabelsData>({
    manualLabel: '',
    firstLineLabel: '',
    secondLineLabel: '',
    cityLabel: '',
    countyLabel: '',
    postcodeLabel: '',
    countryLabel: '',
    loadingText: '',
    notCompleteError: '',
    invalidCountry: ''
  });
  const [isManualMode, setIsManualMode] = useState(false);
  useEffect(() => {
    loadText('address', language).then((res) => setData(res?.data));
  }, [language]);

  const handleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsManualMode(!isManualMode && e?.target?.checked);
    setHasCountryError && setHasCountryError(false);
  };

  const onAddressChange = (fields: { [x: string]: string }, fullAddress: string) => {
    setValues({ ...values, address: { ...fields, [ADDRESS_FIELDS.fullAddress]: fullAddress } });
    setFieldTouched(`address.${ADDRESS_FIELDS.fullAddress}`, true);

    if (typeof onCountryChange === 'function') {
      onCountryChange();
    }
  };

  const setFullAddress = (field: { [x: string]: string }) => {
    const key = Object.keys(field)[0];
    setFieldValue(key, field[0]);
    setFieldTouched(`address.${ADDRESS_FIELDS.fullAddress}`, true);
  };
  return (
    <div className={`form-address ${isManualMode ? 'manual' : ''}`} data-testid="address-lookup">
      {isManualMode ? (
        <Manual
          title={label}
          labels={data}
          setFullAddress={setFullAddress}
          onCountryChange={onCountryChange}
        />
      ) : (
        <Lookup
          onAddressSelect={onAddressChange}
          label={label}
          loadingText={data?.loadingText}
          data={data}
          setHasCountryError={setHasCountryError}
        />
      )}

      <Checkbox
        name={`address.${ADDRESS_FIELDS.manual}`}
        id={`address.${ADDRESS_FIELDS.manual}`}
        label={data?.manualLabel}
        onChange={handleModeChange}
        value={isManualMode}
      />
    </div>
  );
};

export default AddressLookup;
