/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeneralInfoPagePopup, Site } from 'types';

const initialState: Site = {
  statusMessage: '',
  notificationScrollable: true,
  errorMessage: '',
  formErrorMessage: '',
  generalPageInfoPopUp: {
    popupTitle: '',
    popupMessage: ''
  },
  mobileDrawer: false,
  isLoading: false,
  isEwTileClosed: null,
  reduxSyncDb: 0,
  isAnalyticalCookiesAllowed: true,
  isAllDataReady: false,
  isMenuVisible: false
};

const reducers = {
  toggleMobileDrawer(state: Site, { payload }: PayloadAction<boolean>) {
    state.mobileDrawer = payload;
  },
  setNotification(state: Site, { payload }: PayloadAction<string>) {
    state.notificationScrollable = true;
    state.statusMessage = payload;
  },
  setNotificationScrollable(state: Site, { payload }: PayloadAction<boolean>) {
    state.notificationScrollable = payload;
  },
  setError(state: Site, { payload }: PayloadAction<string>) {
    state.errorMessage = payload;
  },
  setFormErrorMessage(state: Site, { payload }: PayloadAction<string>) {
    state.formErrorMessage = payload;
  },

  setLoading(state: Site, { payload }: PayloadAction<boolean>) {
    state.isLoading = payload;
  },
  setGeneralPageInfoPopUp(state: Site, { payload }: PayloadAction<GeneralInfoPagePopup>) {
    state.generalPageInfoPopUp = payload;
  },
  setIsAnalyticalCookiesAllowed(state: Site, { payload }: PayloadAction<boolean>) {
    state.isAnalyticalCookiesAllowed = payload;
  },
  setIsAllDataReady(state: Site, { payload }: PayloadAction<boolean>) {
    state.isAllDataReady = payload;
  },
  setMenuVisibility(state: Site, { payload }: PayloadAction<boolean>) {
    state.isMenuVisible = payload;
  }
};

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers
});

export const {
  toggleMobileDrawer,
  setNotification,
  setNotificationScrollable,
  setError,
  setFormErrorMessage,
  setLoading,
  setGeneralPageInfoPopUp, // popup notiff
  setIsAnalyticalCookiesAllowed,
  setIsAllDataReady,
  setMenuVisibility
} = siteSlice.actions;

export const site = siteSlice.reducer;
