/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import {
  buildAddressFields,
  IAddressData,
  ILookup,
  isAddress
} from 'components/elements/address/helper';
import FormError from 'components/elements/formError';
import { countries } from 'countries-list';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { ILocationAddressValues } from 'types';

import { ADDRESS_FIELDS } from './constants';

let addressInitiated: IAddressData = {
  addressLine1: '',
  city: '',
  region: '',
  postalCode: '',
  country: ''
};

const Lookup: FC<ILookup> = (props) => {
  const { label, onAddressSelect, loadingText, data, setHasCountryError } = props;
  const { values, setFieldValue, errors, getFieldMeta } = useFormikContext();
  const address = getFieldMeta('address').value;

  if (isAddress(address)) {
    addressInitiated = address;
  }
  const [placeSelect, setPlaceSelect] = useState(addressInitiated);
  const [errorList, setErrorList] = useState('');

  const validateCountry = (input: string) => {
    const isValid = Object.values(countries).some(
      (c) => c.name.toLowerCase() === input?.toLowerCase()
    );

    setErrorList(isValid ? '' : data?.invalidCountry);
    setHasCountryError && setHasCountryError(!isValid);
    return isValid;
  };

  useEffect(() => {
    const { addressLine1, postalCode, city, region, country } = placeSelect;
    !addressLine1 || !postalCode || !city || !region || !country
      ? setErrorList(data?.notCompleteError)
      : setErrorList('');
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    setFieldValue(`address.${ADDRESS_FIELDS.fullAddress}`, fullAddress({ ...addressInitiated }));
    // eslint-disable-next-line
  }, []);

  function updateAddress(splitAddress: string[]) {
    const { address: addressObj } = values as ILocationAddressValues;

    const updatedAddress: { [p: string]: string } = {
      ...addressObj,
      addressLine1: splitAddress[0] || '',
      city: splitAddress[1] || '',
      region: splitAddress[2] || '',
      postalCode: splitAddress[3] || '',
      country: splitAddress[4] || ''
    };

    if (!validateCountry(splitAddress[4])) {
      return {
        ...updatedAddress,
        country: ''
      };
    }

    return updatedAddress;
  }

  const handleChange = (address: string) => {
    const splitAddress = address.split(',');
    splitAddress.forEach((el) => el.trim());
    const addressFields = updateAddress(splitAddress);
    onAddressSelect(addressFields, address);

    if (!validateCountry(splitAddress[4])) {
      return;
    }

    setPlaceSelect({
      addressLine1: addressFields.addressLine1 || '',
      postalCode: addressFields.postalCode || '',
      city: addressFields.city || '',
      region: addressFields.region || '',
      country: addressFields.country || ''
    });
  };
  /* istanbul ignore next */
  const handleSelect = (address: string) => {
    geocodeByAddress(address).then((results) => {
      const addressFields = buildAddressFields(results[0]);

      if (!validateCountry(addressFields.country)) {
        return;
      }

      setPlaceSelect({
        addressLine1: addressFields.addressLine1,
        postalCode: addressFields.postalCode,
        city: addressFields.city,
        region: addressFields.region,
        country: addressFields.country
      });
      const addressWithPostCode = fullAddress(addressFields);
      onAddressSelect(addressFields, addressWithPostCode);
    });
  };

  const fullAddress = (addressFields: { [p: string]: string | null }) =>
    [
      addressFields.addressLine1,
      addressFields.city,
      addressFields.region,
      addressFields.postalCode,
      addressFields.country
    ]
      .filter(Boolean)
      .join(', ');

  return (
    <Field name={`address.${ADDRESS_FIELDS.fullAddress}`}>
      {({ field, meta }: FieldProps) => (
        <PlacesAutocomplete
          value={field.value}
          onChange={handleChange}
          onSelect={handleSelect}
          debounce={300}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div
              className={`form-address-lookup ${
                (meta.touched && meta.error) ||
                (errorList && !(loading || suggestions.length) && meta.touched)
                  ? 'error'
                  : ''
              }`}
              data-testid="form-address-lookup"
            >
              {label && <label className="form-address-lookup__label">{label}</label>}
              <div className="form-address-lookup__input">
                <input
                  data-testid="places-autocomplete-input"
                  id={`address.${ADDRESS_FIELDS.fullAddress}`}
                  {...{
                    ...getInputProps({ placeholder: '' }),
                    name: `address.${ADDRESS_FIELDS.fullAddress}`
                  }}
                />
              </div>

              <div
                className={`form-address-lookup__suggestions ${loading || suggestions.length ? 'active' : ''}`}
                data-testid="suggestions-list"
              >
                {loading && <div className="form-address-lookup__suggestion">{loadingText}</div>}
                {suggestions.map((suggestion, idx) => (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: `form-address-lookup__suggestion ${suggestion.active ? 'active' : ''}`
                    })}
                    key={idx}
                    data-testid={`suggestion-${idx}`}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                ))}
              </div>
              {!(loading || suggestions.length) && meta.touched && (
                <FormError forcedShow={errorList} />
              )}
            </div>
          )}
        </PlacesAutocomplete>
      )}
    </Field>
  );
};

export default Lookup;
