/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { Dayjs } from 'dayjs';
import dayjs from 'utils/dayjs';

class DateCreator {
  private timezone = 'Europe/London';

  set Timezone(timezone: string) {
    if (timezone) this.timezone = timezone;
  }

  get Timezone() {
    return this.timezone;
  }

  get now() {
    return dayjs().tz(this.timezone);
  }

  get yesterday() {
    return this.now.subtract(1, 'day');
  }

  get tomorrow() {
    return this.now.add(1, 'day');
  }

  get startOfThisWeek() {
    return this.now.startOf('isoWeek');
  }

  get endOfThisWeek() {
    return this.now.endOf('isoWeek');
  }

  get startOfLastWeek() {
    return this.now.subtract(1, 'week').startOf('isoWeek');
  }

  get endOfLastWeek() {
    return this.now.subtract(1, 'week').endOf('isoWeek');
  }

  get startOfThisMonth() {
    return this.now.startOf('month');
  }

  get endOfThisMonth() {
    return this.now.endOf('month');
  }

  get startOfLastMonth() {
    return this.now.subtract(1, 'month').startOf('month');
  }

  get endOfLastMonth() {
    return this.now.subtract(1, 'month').endOf('month');
  }

  create(from?: number | Date | Dayjs) {
    return dayjs(from).tz(this.timezone);
  }
}

export default new DateCreator();
