/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { calculateTotals } from 'components/graph/chart';
import { Period } from 'constants/enums';
import { DEFAULT_TIMEZONE } from 'shared/js';
import {
  Filter,
  Graphs,
  SetData,
  SetGraphSetsPayload,
  SetIsCompletePayload,
  ToggleFiltersPayload,
  UpdateDateRangePayload,
  UpdateGraphTimezonePayload,
  UpdateGraphTotalsPayload
} from 'types';
import dateCreator from 'utils/dateCreator';

const initialState: Graphs = {
  period: Period.TODAY,
  startDate: dateCreator.now.startOf('day'),
  endDate: dateCreator.now,
  consumedSets: {
    isComplete: false
  },
  usageSets: {
    isComplete: false
  },
  priceSets: {
    isComplete: false
  },
  batterySets: {
    isComplete: false
  },
  filters: {
    consumedSets: {
      imported: { name: 'imported', active: true },
      exported: { name: 'exported', active: true },
      consumed: { name: 'consumed', active: true }
    },
    usageSets: {},
    batterySets: {
      charge: { name: 'charge', active: true },
      discharge: { name: 'discharge', active: true },
      soc: { name: 'soc', active: true }
    }
  },
  totals: []
};

const userGraphSlice = createSlice({
  name: 'userGraphs',
  initialState,
  reducers: {
    setIsComplete(state, { payload: { type, value } }: PayloadAction<SetIsCompletePayload>) {
      state[type].isComplete = value;
    },
    setGraphSets(state, { payload: { data, hubId, type } }: PayloadAction<SetGraphSetsPayload>) {
      state[type][hubId] = {
        graphData: data,
        meta: {
          lastCall: Number(new Date().getTime())
        }
      };
    },
    updateDateRange(
      state,
      { payload: { period, startDate, endDate } }: PayloadAction<UpdateDateRangePayload>
    ) {
      state.period = period;

      switch (period) {
        case Period.TODAY:
          state.startDate = dateCreator.now.startOf('day');
          state.endDate = dateCreator.now;
          break;
        case Period.YESTERDAY:
          state.startDate = dateCreator.yesterday.startOf('day');
          state.endDate = dateCreator.yesterday.endOf('day');
          break;
        case Period.TOMORROW:
          state.startDate = dateCreator.tomorrow.startOf('day');
          state.endDate = dateCreator.tomorrow;
          break;
        case Period.THIS_WEEK:
          state.startDate = dateCreator.startOfThisWeek;
          state.endDate = dateCreator.endOfThisWeek;
          break;
        case Period.LAST_WEEK:
          state.startDate = dateCreator.startOfLastWeek;
          state.endDate = dateCreator.endOfLastWeek;
          break;
        case Period.THIS_MONTH:
          state.startDate = dateCreator.startOfThisMonth.startOf('day');
          state.endDate = dateCreator.endOfThisMonth;
          break;
        case Period.LAST_MONTH:
          state.startDate = dateCreator.startOfLastMonth.startOf('day');
          state.endDate = dateCreator.endOfLastMonth;
          break;
        case Period.CUSTOM:
          state.startDate = startDate?.tz(dateCreator.Timezone, true);
          state.endDate =
            endDate?.tz(dateCreator.Timezone, true) ||
            startDate?.tz(dateCreator.Timezone, true).endOf('day');
          break;
      }
    },
    createUsageFilters(state, { payload }: PayloadAction<Filter>) {
      state.filters.usageSets = payload;
    },
    toggleFilters(state, { payload: { type, name, value } }: PayloadAction<ToggleFiltersPayload>) {
      state.filters[type][name] = value;
    },
    updateGraphTotals(
      state,
      { payload: { hubId, type } }: PayloadAction<UpdateGraphTotalsPayload>
    ) {
      const setData = state[type][hubId] as SetData;

      setData.totals = calculateTotals(
        setData.graphData,
        state.period,
        state.startDate,
        state.endDate
      );
    },
    updateDatesTimeZone(
      state,
      { payload: { timeZone } }: PayloadAction<UpdateGraphTimezonePayload>
    ) {
      timeZone = timeZone || DEFAULT_TIMEZONE;

      state.startDate = state.startDate.tz(timeZone, true);
      state.endDate = state.endDate.tz(timeZone, true);
    }
  }
});

export const {
  setIsComplete,
  setGraphSets,
  updateDateRange,
  toggleFilters,
  createUsageFilters,
  updateGraphTotals,
  updateDatesTimeZone
} = userGraphSlice.actions;
export const userGraphs = userGraphSlice.reducer;
