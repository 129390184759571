/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { getCookie } from 'components/platform/cookiebar/helper';
import { getCodeFromLanguage } from 'components/sitePreferences/helpers';
import { CURRENCY, dateFormated, distanceFormated, FL, LANGUAGES } from 'shared/js';
import { PersonalInfo } from 'types';

import {
  PersonalInfoResContent,
  UserDetailsResContent,
  UserPrefsResContent
} from './processors.types';

export const processAllPersonalInfo = (obj: UserDetailsResContent): PersonalInfo => {
  return {
    title: obj.personalInfo.title,
    firstName: obj.personalInfo.firstName,
    lastName: obj.personalInfo.lastName,
    accountType: obj.personalInfo.accountType,
    creationDate: obj.personalInfo.creationDate,
    email: obj.personalInfo.email,
    birthDate: dateFormated(obj.personalInfo?.birthDate, FL.APP),
    contactAddress:
      obj.personalInfo.contactAddress !== null
        ? { ...obj.personalInfo.contactAddress }
        : {
            id: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            country: '',
            countryCode: '',
            postalCode: '',
            region: '',
            isContactAddress: true
          },
    mobileNumber: obj.personalInfo.mobileNumber,
    mobileCode: obj.personalInfo.mobileCode,
    landlineNumber: obj.personalInfo.landlineNumber,
    landlineCode: obj.personalInfo.landlineCode,
    marketing: { ...obj.marketing },
    preferences: {
      distance: distanceFormated(obj.preferences.isMetric), //isMetric
      dateFormat: dateFormated(obj.preferences.dateFormat, 'a'),
      language:
        getCookie('preferedLanguage') || getCodeFromLanguage[navigator.language] || LANGUAGES.EN_GB,
      currency: CURRENCY.GBP, // obj.preferences.currency
      country: getCookie('preferedCountry') || 'gb'
    },
    displayedTiles: {
      displayAccountSharingTile: obj.displayedTiles?.displayAccountSharingTile,
      displayElectricVehicleTile: obj.displayedTiles?.displayElectricVehicleTile,
      displayEwTile: obj.displayedTiles?.displayEwTile,
      displayUserJourneyBoard: obj.displayedTiles?.displayUserJourneyBoard
    },
    userFlags: {
      isBetaTester: obj.userFlags?.isBetaTester,
      isSelectedForDSR: obj.userFlags?.isSelectedForDSR,
      isOptedIn: obj.userFlags?.isOptedIn,
      isZappiGActive: obj.userFlags?.isZappiGActive,
      superUserFlags: {
        isSuperUserAvailable: obj.userFlags?.superUserFlags?.isSuperUserAvailable,
        isSuperUserLinkedToTheClient: obj.userFlags?.superUserFlags?.isSuperUserLinkedToTheClient
      }
    }
  };
};

export const processPersonalInfo = (
  {
    title,
    firstName,
    lastName,
    address,
    dateOfBirth,
    mobileNumber,
    mobileCode,
    landlineNumber,
    landlineCode
  }: PersonalInfoResContent,
  contactAddressId: string,
  personalInfo: PersonalInfo
) => {
  return {
    ...personalInfo,
    title,
    firstName,
    lastName,
    contactAddress:
      address !== null
        ? { ...personalInfo.contactAddress, ...address, id: contactAddressId }
        : { ...personalInfo.contactAddress },
    birthDate: dateOfBirth,
    mobileNumber,
    mobileCode,
    landlineNumber,
    landlineCode
  };
};

export const processPreferences = (obj: UserPrefsResContent) => {
  return {
    id: obj.id,
    distance: distanceFormated(obj.isMetric), //isMetric
    dateFormat: dateFormated(obj.dateFormat, 'a'),
    language:
      getCookie('preferedLanguage') || getCodeFromLanguage[navigator.language] || LANGUAGES.EN_GB,

    currency: CURRENCY.GBP, // obj.preferences.currency
    country: getCookie('preferedCountry') || 'gb'
  };
};
